import { AzureHelper } from "../AzureMSAL/AzureHelper";
import { AuthScheme } from "../interface";

export class AuthHelper {
  static keepLoginSession(): void {
    switch (AuthHelper.authScheme) {
      case AuthScheme.ability:
      case AuthScheme.oidc:
        return AzureHelper.keepLoginSession(); 
      default:
        return;
    }
  }
  static authScheme:string;
  static init(authName:string){
    AuthHelper.authScheme = authName;

  }
  static async getRefreshAccessToken(): Promise<string> {
    switch (AuthHelper.authScheme) {
      case AuthScheme.ability:
      case AuthScheme.oidc:
        return AzureHelper.getRefreshAccessToken();
      default:
        return {} as any;
    }
  }
  static getAccessToken(): string {
    switch (AuthHelper.authScheme) {
      case AuthScheme.ability:
      case AuthScheme.oidc: 
        return AzureHelper.getAccessToken();      
      default:
        return "";
    }
  }
  static getTenant(): string {
    switch (AuthHelper.authScheme) {
      case AuthScheme.ability:
      case AuthScheme.oidc:
        return AzureHelper.getTenant();
      default:
        return "";
    }
  }

  static logOut() {
    switch (AuthHelper.authScheme) {
      case AuthScheme.ability:
      case AuthScheme.oidc:
        return AzureHelper.logOut(); 
      default:
        return;
    }
  }
}
