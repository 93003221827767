import React from "react";
import { AuthConfiguration, AuthScheme, AuthEvents } from "../interface";
import AzureLogin from "../AzureMSAL/AzureLogin";
import { AuthHelper } from "./AuthHelper";
import { Loader } from "./Loader"; 
import "../style/index.scss";

interface State {
  authScheme: string;
  isTokenRecieved: boolean;
}

export class AuthProvider extends React.Component<AuthConfiguration, State> {
  timeoutObj;
  constructor(props: AuthConfiguration) {
    super(props);
    AuthHelper.init(this.props.authType);
    let event: AuthEvents = {
      getToken: () => AuthHelper.getAccessToken(),
      getTenantName: () => AuthHelper.getTenant(),
      logout: () => AuthHelper.logOut(),
      keepLoginSession: () => AuthHelper.keepLoginSession()
    };
    this.state = {
      authScheme: this.props.authType,
      isTokenRecieved: false,
    };
    this.props.onEventRegister(event);
    if ( this.props.authType == AuthScheme.ability && this.props.storage == 'localStorage' ) {
      window.addEventListener('storage', (ev) => {
        if (ev.key === "loginInformationChanged") {
          setTimeout(()=>window.location.reload(),2000)
        }
      });
    }
  }

  renderLogin(authScheme: string) {
    switch (authScheme) {
      case AuthScheme.ability:
      case AuthScheme.oidc:
        return this.loginAzure(); 
      case AuthScheme.none:
          return this.loginBASE();
      default:
        return this.loginBASE();
    }
  }
  componentDidMount() {
  }

  loginAzure() {
    return <AzureLogin {...this.props}><Loader>{this.props.children}</Loader></AzureLogin>
  }
  
  loginBASE() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }

  render() {
    return (
      <React.Fragment>{this.renderLogin(this.state.authScheme)}</React.Fragment>
    );
  }
}
