export interface AuthConfiguration {
  authType: "AbilityB2C" | "OIDC" | "None";
  storage: "sessionStorage" | "localStorage";
  applicationName: string;
  authModel: AuthModel;
  keepLiveSession?: boolean;
  timeOutSeconds?: number;
  onEventRegister: onEventRegister;
  onUpdateToken?: (token: string) => void;
  onTokenExpriry?: (state: boolean) => void;
  onBeforeLogout?: () => void; 
}

export interface onEventRegister {
  (events: AuthEvents): void;
}

export interface AuthEvents {
  getToken(): string;
  getTenantName(): string;
  logout(): void;
  keepLoginSession(): void;
}

export interface AuthModel {
  b2cName?: string;
  authority?: string;
  clientId: string;
  redirectUrl: string;
  postLogoutRedirectUrl: string;
  scope: string;
  tokenRenewalOffsetSeconds?: number;
}

export const AuthScheme = {
  ability: "AbilityB2C",
  oidc: "OIDC",
  none: "None",
};

export const StorageItems = {
  NLS: "nls-lang",
  AccessToken: "access_token",
  Token: "token",
  IdToken: "id_token",
  Tenant: "tenant",
  MSALConfig: "MsalConfig",
  ExpiredON: "expiredOn",
  LoggedInUser: "loggedInUser",
  ShowLanding: "showLanding",
  Contents: "contents",
  OIDCConfig: "OIDCConfig",
  AuthParams: "AuthParams",
  OIDCAuthInfo: "oidc_auth_info",
};